import React, {useEffect} from 'react';
import Util from "../../../../components/util/util";
import Button from "reactstrap/lib/Button";
import {Translation} from "@autopay.io/translation";
import {constants} from "../../../../constants";
import moment from "moment";
import {PlateWithStatus} from "../../components/PlateWithStatus";
import {DisplayVehicleCharacteristics} from "../../components/DisplayVehicleCharacteristics";
import {ValidationAndVehicleInformation} from "../../types";
import {ResponsiveSpacer} from "../../../../components/ResponsiveSpacer";

interface SuccessProps {
    plateId?: string;
    duration: number | null;
    reset: () => void;
    validationInfo: ValidationAndVehicleInformation | null;
}

export const Success = (props: SuccessProps) => {
    const {validationInfo, duration, reset, plateId} = props;
    const startTime = validationInfo?.validation_result?.validation_response?.start_time;
    const endTime = validationInfo?.validation_result?.validation_response?.end_time;

    useEffect(() => {
        setTimeout(() => reset(), constants.TIMEOUT_TIME);
    }, []);

    const getValidationMsg = () => {
        if (startTime && endTime) {
            const momentStartTime = moment.parseZone(startTime);
            const momentEndTime = moment.parseZone(endTime);
            const currentFacilityTime = moment();
            const showDate = momentStartTime.isSame(momentEndTime, "day") ?
                !momentStartTime.isSame(currentFacilityTime, "day") : true;
            const TimeSpan = (time: string) => <span>{time}</span>;

            return (
                <h1>
                    {Translation.messages().tapnpark.device.free_parking}
                    {' '}
                    <span className="parking-validated-title">
                       {Translation.tokenize(Translation.messages().tapnpark.device.time_until)
                           .decorate('startTime', () => TimeSpan(Util.formatDateTime(startTime, showDate)))
                           .decorate('endTime', () => TimeSpan(Util.formatDateTime(endTime, showDate)))
                           .build()()}
                    </span>
                </h1>
            );
        }

        return (
            <h1>
                {duration ? Util.secondsToHms(duration) : ''}
                {' '}
                {Translation.messages().tapnpark.device.title}
            </h1>
        );
    };

    const translationFallback = (translationMessage: string, fallBackMessage: string) => {
        if (translationMessage !== '') {
            return translationMessage;
        }
        return fallBackMessage
    }

    const getSubTitleMsg = () => {
        const momentStartTime = moment.parseZone(startTime);
        const momentEndTime = moment.parseZone(endTime);
        const currentFacilityTime = moment();
        const showDate = momentStartTime.isSame(momentEndTime, "day")
            ? !momentStartTime.isSame(currentFacilityTime, "day") : true;

        return (
            <p className="parking-validated-subtitle">
                {endTime ? translationFallback(Translation.tokenize(
                            Translation.messages().tapnpark.device.free_period_endtime)
                            .setValue("endTime", Util.formatDateTime(endTime, showDate))
                            .buildString(),
                        Translation.messages().tapnpark.device.free_period_endtime)
                    : Translation.messages().tapnpark.device.free_period_ends_on_exit}
            </p>
        );
    };

    return (
        <>
            <PlateWithStatus plateId={plateId || ''} statusIcon="SUCCESS" />
            {validationInfo?.vehicle_brand && (
                <>
                    <ResponsiveSpacer size="sm" />
                    <DisplayVehicleCharacteristics
                        vehicleBrand={validationInfo?.vehicle_brand}
                        vehicleModel={validationInfo?.vehicle_model}
                        vehicleColor={validationInfo?.vehicle_color}
                    />
                </>
            )}
            <ResponsiveSpacer size="md" />
            {getValidationMsg()}
            <ResponsiveSpacer size="sm" />
            {getSubTitleMsg()}
            <ResponsiveSpacer size="md" />
            <Button color="primary" onClick={reset} size="lg">
                {Translation.messages().tapnpark.device.reset}
            </Button>
        </>
    );
};
